/* eslint no-shadow: ["error", { "allow": ["state"] }]*/

export const state = () => ({
	ssoUrl: '',
	loginUrls: [],
	clientId: null,
	redirectUri: null,
});

export const mutations = {
	SET_SSO_URL (state, payload) {
		state.ssoUrl = payload;
	},

	SET_LOGIN_URL (state, payload) {
		state.loginUrl = payload;
	},

	SET_CLIENT_ID (state, payload) {
		state.clientId = payload;
	},

	SET_REDIRECT_URI (state, payload) {
		state.redirectUri = payload;
	},
};
